import { ValidationObserver, ValidationProvider } from 'vee-validate'
import globalHelper from '@/mixins/global'

export default {
  name: 'user-reset',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      email: null,
      password: null,
      cPassword: null,
      resetting: false
    }
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },
  computed: {

  },

  methods: {
    async reset (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.resetting) {
        return
      }

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      const body = {
        token: this.$route.params.token || null,
        email: this.email,
        password: this.password
      }

      this.resetting = true
      this.$store.dispatch('user_reset', body).then(
        (response) => {
          this.resetting = false

          // reset form
          this.email = null
          this.password = null
          this.cPassword = null
          // You should call it on the next frame
          requestAnimationFrame(() => {
            this.$refs.observer.reset()
          })

          this.$events.$emit('user:reset', response)
        },
        (response) => {
          this.resetting = false
          this.printErrors(response, this.$refs.observer)
        }
      )
    }
  }
}
