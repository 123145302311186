import UserReset from '@/components/UserReset/main'

export default {
  name: 'users-reset',
  components: {
    UserReset
  },
  data () {
    return {
    }
  },
  created () {
    this.$events.$on('user:reset', (userData) => {
      this.$router.push({ name: 'UsersLogin', params: { action: 'login' } })
    })
  },
  beforeDestroy () {
    this.$events.$off('user:reset')
  },

  computed: {
  },
  watch: {
  },
  methods: {
  }
}
